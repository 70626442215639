import React from "react"
import { PageProps, graphql } from "gatsby"
import Helmet from "react-helmet"

import { SEO } from "src/components/seo"
import { Carousel } from "src/components/carousel"
import { VideoLoopResponsive } from "src/components/video-responsive"
import { ImgFluid } from "src/components/img-fluid"

import {
  HeadlineAndParagraph,
  HeaderHero,
  CaseStudyCapabilities,
} from "src/components/layouts"

import {
  Container,
  Block,
  Grid,
  Col,
  HorizontalRuler,
  Headline,
  Paragraph,
  ParagraphTall,
  HeadlineHero,
} from "src/components/primitives"

import { useLightTheme } from "src/context/theme"

type DataProps = {
  project: {
    seo: {
      title: string
      description: string
      image: {
        publicURL: string
      }
    }
    copy: {
      title: string
      subtitle: string
      intro: string
      vision_title: string
      vision_copy: string
      ccc_title: string
      ccc_copy: string
      commissioner_title: string
      commissioner_copy: string
      artist_title: string
      artist_copy: string
      artist_2_copy: string
      collateral_title: string
      collateral_copy: string
      expertise: string 

    }
    images: {
      hero_video_static: any
      ux_diagram_static: any
      brand_video_static: any
      grid_1_montage_static: any
      grid_1_commission_static: any
      grid_1_artistcards_static: any
      grid_1_images_static: any
      grid_1_songdelivery_static: any
      grid_2_profile_static: any
      grid_2_package_creation_static: any
      grid_2_notifications_static: any
      grid_2_spotify_static: any
      noted_slides_01: any
      noted_slides_02: any
      noted_slides_03: any
      noted_slides_04: any
      grid_3_kinetic_static: any
      grid_3_social_slides_01: any
      grid_3_social_slides_02: any
      grid_3_social_slides_03: any
      grid_3_collateral_slides_01: any
      grid_3_collateral_slides_02: any
      grid_3_collateral_slides_03: any
      grid_3_social_3up_static: any
    }

    videos: {
      hero_video: any
      brand_video: any
      grid_1_commission: any
      grid_1_artistcards: any
      grid_1_images: any
      grid_1_songdelivery: any
      grid_2_profile: any
      grid_2_package_creation: any
      grid_2_notifications: any
      grid_2_spotify: any
      grid_3_kinetic: any
    }
  }
}

const CaseStudy: React.FC<PageProps<DataProps>> = ({ data }) => {
  const { seo, copy, images, videos } = data.project

  useLightTheme()

  return (
    <article aria-label={copy.title}>
      <Helmet
        bodyAttributes={{
          class: "theme-light",
        }}
      />
      <SEO
        title={seo.title}
        description={seo.description}
        image={seo.image.publicURL}
      />
      <Container>
        <HeaderHero>
          <HeadlineHero>
            {copy.title}
            <span>{copy.subtitle}</span>
          </HeadlineHero>
        </HeaderHero>
      </Container>

      <Container bleed>
        <Block>
          <VideoLoopResponsive
            video={videos.hero_video}
            image={images.hero_video_static}
          />
        </Block>
      </Container>

      <Container>
        <Paragraph>{copy.intro}</Paragraph>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.vision_title}</Headline>
          <Paragraph>{copy.vision_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <ImgFluid image={images.ux_diagram_static} />
        </Block>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.ccc_title}</Headline>
          <Paragraph>{copy.ccc_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <VideoLoopResponsive
            video={videos.brand_video}
            image={images.brand_video_static}
          />
        </Block>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.commissioner_title}</Headline>
          <Paragraph>{copy.commissioner_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      {/* ---------- separate container */}

      {/* <Container>
        <Block>
          <Grid gap columns={{ bp0: "$1", bp1: "$2" }}>
            <Col
              start={{ bp0: "$1", bp1: "$1" }}
              end={{ bp0: "$2", bp1: "$3" }}
              >
              <ImgFluid image={images.grid_1_montage_static} />
            </Col>
          </Grid>
        </Block>
      </Container> */}

      <Container>
        <Block>
          <Grid gap columns={{ bp0: "$1", bp1: "$2" }}>
            <Col
              start={{ bp0: "$1", bp1: "$1" }}
              end={{ bp0: "$2", bp1: "$3" }}
              >
              <ImgFluid image={images.grid_1_montage_static} />
            </Col>
            <VideoLoopResponsive
              ratio="$1/1"
              video={videos.grid_1_commission}
              image={images.grid_1_commission_static}
            />
            <VideoLoopResponsive
              ratio="$1/1"
              video={videos.grid_1_artistcards}
              image={images.grid_1_artistcards_static}
            />
            <VideoLoopResponsive
              ratio="$1/1"
              video={videos.grid_1_images}
              image={images.grid_1_images_static}
            />
            <VideoLoopResponsive
              ratio="$1/1"
              video={videos.grid_1_songdelivery}
              image={images.grid_1_songdelivery_static}
            />
          </Grid>
        </Block>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.artist_title}</Headline>
          <Paragraph>{copy.artist_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <Grid gap columns={{ bp0: "$1", bp1: "$2" }}>
            <Col
              start={{ bp0: "$1", bp1: "$1" }}
              end={{ bp0: "$2", bp1: "$3" }}
              >
              <VideoLoopResponsive
                ratio="$16/9"
                video={videos.grid_2_profile}
                image={images.grid_2_profile_static}
              />
            </Col>
            <VideoLoopResponsive
              ratio="$3/4"
              video={videos.grid_2_package_creation}
              image={images.grid_2_package_creation_static}
            />
            <VideoLoopResponsive
              ratio="$3/4"
              video={videos.grid_2_notifications}
              image={images.grid_2_notifications_static}
            />
            <Col
              start={{ bp0: "$1", bp1: "$1" }}
              end={{ bp0: "$2", bp1: "$3" }}
              >
              <VideoLoopResponsive
                ratio="$16/9"
                video={videos.grid_2_spotify}
                image={images.grid_2_spotify_static}
              />
            </Col>
          </Grid>
        </Block>
      </Container>

      <Container>
        <Grid gap columns={{ bp4: "$12" }}>
          <Col start={{ bp4: "$6" }} end={{ bp4: "$13" }}>
            <ParagraphTall>{copy.artist_2_copy}</ParagraphTall>
          </Col>
        </Grid>
      </Container>

      <Container>
        <Block> 
          <Col>
            <Carousel
              images={[
                images.noted_slides_01,
                images.noted_slides_02,
                images.noted_slides_03,
                images.noted_slides_04,
              ]}
            />
          </Col>
        </Block>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.collateral_title}</Headline>
          <Paragraph>{copy.collateral_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <Grid gap columns={{ bp0: "$1", bp1: "$2" }}>
            <Col
              start={{ bp0: "$1", bp1: "$1" }}
              end={{ bp0: "$2", bp1: "$3" }}
              >
              <ImgFluid image={images.grid_3_social_3up_static} />
            </Col>
            <Col>
              <Carousel
                ratio="$3/4"
                images={[
                  images.grid_3_social_slides_01,
                  images.grid_3_social_slides_02,
                  images.grid_3_social_slides_03,
                ]}
              />
            </Col>
            <Col>
              <Carousel
                ratio="$3/4"
                images={[
                  images.grid_3_collateral_slides_01,
                  images.grid_3_collateral_slides_02,
                  images.grid_3_collateral_slides_03,
                ]}
              />
            </Col>
            <Col
              start={{ bp0: "$1", bp1: "$1" }}
              end={{ bp0: "$2", bp1: "$3" }}
              >
              <VideoLoopResponsive
                ratio="$16/9"
                video={videos.grid_3_kinetic}
                image={images.grid_3_kinetic_static}
              />
            </Col>
          </Grid>
        </Block>
      </Container>

      <Container>
        <CaseStudyCapabilities>{copy.expertise}</CaseStudyCapabilities>
      </Container>
    </article>
  )
}

export default CaseStudy

export const pageQuery = graphql`
  query {
    project: workJson(id: { eq: "downwrite" }) {
      id
      seo {
        title
        description
        image {
          publicURL
        }
      }
      copy {
        title
        subtitle
        intro
        vision_title
        vision_copy
        ccc_title
        ccc_copy
        commissioner_title
        commissioner_copy
        artist_title
        artist_copy
        artist_2_copy
        collateral_title
        collateral_copy
        expertise 
      }

      images {
        hero_video_static {
          alt
          src {
            childImageSharp {
              ...HeroImageFragment
            }
          }
        }
        ux_diagram_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        brand_video_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_1_montage_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_1_commission_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_1_artistcards_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_1_images_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_1_songdelivery_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_2_profile_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_2_package_creation_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_2_notifications_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_2_spotify_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        noted_slides_01 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        noted_slides_02 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        noted_slides_03 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        } 
        noted_slides_04 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_3_kinetic_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_3_social_slides_01 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_3_social_slides_02 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_3_social_slides_03 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_3_collateral_slides_01 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_3_collateral_slides_02 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_3_collateral_slides_03 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_3_social_3up_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }      

      }
      videos {
        hero_video {
          src {
            src_large
            src_medium
            src_small
          }
        }
        brand_video {
          src {
            src_large
            src_medium
            src_small
          }
        }
        grid_1_commission {
          src
        }
        grid_1_artistcards {
          src
        }
        grid_1_images {
          src
        }
        grid_1_songdelivery {
          src
        }
        grid_2_profile {
          src {
            src_large
            src_medium
            src_small
          }
        }
        grid_2_package_creation {
          src
        }
        grid_2_notifications {
          src
        }
        grid_2_spotify {
          src {
            src_large
            src_medium
            src_small
          }
        }
        grid_3_kinetic {
          src {
            src_large
            src_medium
            src_small
          }
        }  

      }
    }
  }
`